import Vue from 'vue/dist/vue.esm.js';
import VCalendar from 'v-calendar';


const SocialIdeaCalendar = () => {

    if ( document.getElementById('appcalendar') ) {
        let wrapper_calendario = document.getElementById('wrapper_calendario');
        let offsetto = wrapper_calendario.getBoundingClientRect();
        Vue.use(VCalendar);
        new Vue({
            el: '#appcalendar',
            data() {
                return {
                  month : null,
                  year : null,
                  cat : '*',
                  selectedDay: null, // Add state to store selected day
                  events: [
                    // {
                    //   id: 1,
                    //   description: 'Clean the house.',
                    //   date: new Date(2022, 2, 1),
                    //   color: 'red',
                    //   dot: false,
                    // },
                  ],
                };
            },
            computed: {
                attributes() {
                    return this.events.map(t => ({
                        key: `event.${t.id}`,
                        dot: {
                            style: {
                              backgroundColor: t.color,
                            }
                        },
                        //highlight: true,
                        //bar: true,
                        dates:  {
                            start: t.start,
                            end: t.end,
                        },
                        customData: t,
                    }));
                },
            },
            mounted() {
                console.log('mounted');
                //this.getEvents();
            },
            watch: {
                cat: function(val) {
                    console.log(val);
                    this.getEvents(this.month,this.year,this.cat);
                    //do something when the data changes.
                    // if (val) {
                    //     this.makeSmthWhenDataChanged();
                    // }
                }
            },
            methods: {
                getEvents (month,year,cat) {
                    console.log(socialjs);
                    const data = new FormData();
                    
                    data.append( 'action', 'socialidea_get_events' );
                    data.append( 'nonce', socialjs.NONCE );
                    data.append( 'month', month );
                    data.append( 'year', year );
                    data.append( 'cat', cat );

                    fetch(socialjs.AJAX_URL,{
                        method: "POST",
                        body: data,
                    })
                    .then(res => res.json())
                    .then(data => {
                        this.events = data;
                    });
                },
                pageChange (page) {
                    console.log(page);
                    this.selectedDay = null;
                    this.month = page.month;
                    this.year = page.year;
                    this.getEvents(this.month,this.year,this.cat);
                },
                dayClicked(day) {
                    window.scrollTo({
                        top: offsetto.bottom,
                        left: 0,
                        behavior: 'smooth'
                      });
                    this.selectedDay = day;
                },
                filterEvent ( term_id ,event) {
                    console.log(event);
                    console.log(term_id);
            
                    let elements=document.getElementsByClassName('btnfiltro')

                    for(element of elements){
                        element.classList.remove('active')
                    }
                    event.target.classList.add('active');
                    this.selectedDay = null;
                    this.cat = term_id;
                }
              },
        })

    }

}

export default SocialIdeaCalendar;