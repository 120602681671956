import Swiper from 'swiper/swiper-bundle.js';

const SocialIdeaSlider = () => {
  const hasSlider = document.querySelectorAll('.socialidea-slider');

  if( hasSlider.length == 0 ) return;
  
  new Swiper(".socialidea-slider", {
    slidesPerView: 1,
    grabCursor: true,
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

}


export default SocialIdeaSlider