import Carousel from './components/carousel';
import SocialIdeaCalendar from './components/calendar';
import SocialIdeaRicerca from './components/ricerca';
import SocialIdeaSlider from './components/slider';

function Init() {
  Carousel();
  SocialIdeaCalendar();
  SocialIdeaRicerca();
  SocialIdeaSlider();
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', Init );
} else {
  Init();
}