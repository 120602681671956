import Swiper from 'swiper/swiper-bundle.js';

const Carousel = () => {
  const hasSlider = document.querySelectorAll('.socialidea-swiper');

  if( hasSlider.length == 0 ) return;
  
  new Swiper(".socialidea-swiper", {
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 15
      },
      999: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      1200: {
        slidesPerView: 2.5,
        spaceBetween: 30
      }
    }
  });

}


export default Carousel