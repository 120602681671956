import Typed from 'typed.js';

const SocialIdeaRicerca = () => {

    if ( document.getElementById('socialidearicerca') ) {
        let paroloni = [];
        let parole = document.querySelectorAll('.parole');

        parole.forEach((parola) => {
            paroloni.push(parola.innerHTML);
        });

        let typed = new Typed('.searchbar-animated', {
            strings: paroloni,
            typeSpeed: 60,
            backSpeed: 15,
            attr: "placeholder",
            bindInputFocusEvents: true,
            loop: true
        });
    }

}

export default SocialIdeaRicerca;